import { gql } from '@apollo/client';

export const FutQuerys = {
  getRegulamento: gql`
    query {
      regulamento
    }
  `,
  consultaBilheteFutebol: gql`
    query Query($codigo: String) {
      futebolsite_consultaBilheteFutebol(codigo: $codigo)
    }
  `,
  consultaReferencia: gql`
    query Query($codigo: String) {
      futebolsite_consultaReferencia(codigo: $codigo)
    }
  `,
  getEventosFutebol: gql`
    query Futebol($busca: String, $data: String) {
      getEventosFutebol(busca: $busca, data: $data) {
        liga
        partidas {
          _id
          base_id
          liga {
            nome
          }
          cotacoes_principais {
            mais
            cotacoes {
              id
              sigla
              valor
              sigla_id
            }
          }
          casa
          fora
          casa_img
          fora_img
          data_hora
          data_hora_br
        }
      }
    }
  `,
  getUmEventoFutebol: gql`
    query Futebol($base_id: String) {
      getEventoCotacoesSite(base_id: $base_id) {
        _id
        casa
        fora
        casa_img
        fora_img
        data_hora_br
        base_id
        cotacoes {
          ao_vivo
          suspended
          ativada
          fixture_id
          id
          sigla_id
          sigla
          event_id
          last_update
          valor
          valorb
        }
      }
    }
  `,
};
